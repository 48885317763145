.login-container {
  padding: 2rem;
  background: #293042;
  box-shadow: 0 0 0.875rem 0 rgb(41 48 66 / 5%);
  border-radius: 0.25rem;
  margin-bottom: 80px;

  .no-background-input {
    background: none !important;
    border: 1px solid #7f838e !important;
    color: #bfc1c6 !important;
  }
}

.white-text {
  color: #ffffff;
}